@use 'src/Variable';

.formLabel {
  margin-bottom: 2px;
  font-size: 0.9rem;
}
.bottomBorder {
  border-bottom: 1px solid #ddd;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.dspLabel {
  font-size: 0.765rem;
  color: Variable.$dspItelLabelFontColor;
}
.dspValue {
  padding-left: 0px;
}
.sectionBorder {
  border-bottom: 1px solid #a5a5a5;
  position: relative;
}
.sectionTitleWrapper {
  margin-top: 8px;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  .title {
    background-color: #fff;
    color: #626262;
    padding: 0 18px;
    font-weight: 650;
    font-size: 1.05rem;
    display: inline-block;
  }
}
.sectionChildrenWrapper {
  margin-left: 7px;
  margin-right: 7px;
}
:global {
  .warn-toast {
    background-color: #ffffb7;
    div {
      &:first-child {
        color: #ffc107;
        font-size: 1.2rem;
      }
    }
  }
  .error-toast {
    background-color: #ffb7db;
  }
  .success-toast {
    background-color: #b7ffb7;
  }
  .portal-error-message {
    font-size: 0.75rem;
    display: block;
    color: #ff7f7f;
  }
}