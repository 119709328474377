@use 'src/Theme';

$topBarHeight: 50px;
$leftPanelWidth: 400px;

.topBar {
  background-color: Theme.$mainColor;
  height: $topBarHeight;
}
.navBtn {
  background-color: Theme.$mainColor;
}
.brand {
  font-size: 1.3em;
  color: white;
  padding-left: 10px;
}
.loginUser {
  color: white;
  padding-right: 10px;
  margin-top: 4px;
}
.menuPanel {
  padding-right: 10px;
}
.dropdownBtn {
  background-color: Theme.$mainColor;
  border: none;
  color: #fff;
}
.menuButton {
  padding-right: 10px;
  color: white;
}
.dropDownPanel {
  width: 230px;
  margin-left: -190px;
  margin-top: 40px;
}

.pageContainer {
  height: calc(100% - 50px);
  padding: 8px;
  font-size: 0.9rem;
}

.leftPanel {
  float: left;
  width: $leftPanelWidth;
  height: calc(100vh - #{$topBarHeight});
}
.rightPanel {
  float: left;
  width: calc(100% - #{$leftPanelWidth});
  height: calc(100vh - #{$topBarHeight});
}
