$mainColor: #027be3;
$subColor: #d0e9ff;

$commonRightColor: #2d9db4;
$commonMainColor: #cad10a;
$commonLeftColor: #ebad2a;

$paymentedColor: #7fbfff;
$approvedColor: #c4ff89;
$turnInColor: #ffce9e;
$notTurnInColor: #ffb7db;
