@use 'src/Theme';
@use 'src/Variable';
@use 'src/Common';

@mixin reportList {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 4px;
}

.listWrapper {
  color: black;
  .paymented {
    background-color: Theme.$paymentedColor;
    @include reportList;
  }
  .approved {
    background-color: Theme.$approvedColor;
    @include reportList;
  }
  .turnIn {
    background-color: Theme.$turnInColor;
    @include reportList;
  }
  .notTurnIn {
    background-color: Theme.$notTurnInColor;
    @include reportList;
  }
  .title {
    font-weight: 800;
  }
  .status {
    float: left;
    width: 80px;
    color: #0f5132;
  }
  .date {
    float: left;
    text-align: right;
    width: calc(100% - 80px);
    color: #0f5132;
    span {
      color: black;
      font-size: 0.8rem;
      margin-right: 8px;
    }
  }
}

.reportTopWrapper {
  .summary {
    margin-bottom: 8px;
  }
  .personalPanel {
    padding: 0.5rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-color: Theme.$mainColor;
    margin-bottom: 1rem;
    .reportBtn {
      background-color: Theme.$mainColor;
      color: #fcf8f8;
      font-size: 14px;
      border-radius: 30px;
      margin-top: 0.5rem;
    }
    .next {
      font-size: 0.9rem;
      color: #525151;
      margin-bottom: 0.7rem;
      padding-right: 0px;
    }
  }
  .settlementPanel {
    padding: 0.5rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-color: Theme.$commonLeftColor;
    margin-bottom: 1rem;
    .subTitle {
      padding-left: 1rem;
      color: #525151;
    }
    .fee {
      font-size: 1.6rem;
    }
    .settlementBtn {
      background-color: Theme.$commonLeftColor;
      color: #fcf8f8;
      font-size: 14px;
      border-radius: 30px;
      margin-top: 0.5rem;
    }
  }
  .settlementHistory {
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-decoration: none;
    svg {
      margin-right: 0.25rem;
    }
  }
}

.spaceB {
  margin-bottom: 0.5rem;
}
.slashSeparate {
  text-align: center;
  margin-top: 0.2rem;
}
.editFormWrapper {
  .teacherName {
    color: #626262;
    text-align: center;
  }
  .header {
    margin-top: 1rem;
    background-color: Theme.$subColor;
    border: none;
    .title {
      text-align: center;
      color: #7a7a7a;
      font-size: 0.75rem;
      margin-top: 0.7rem;
    }
  }
  .studentPanel {
    .studentName {
      color: #626262;
      text-align: center;
      font-size: 1.3em;
      font-weight: bold;
    }
  }
  .pagerBtn {
    background-color: Theme.$subColor;
    width: 45px;
    height: 45px;
    color: #7f7f7f;
  }
  .card {
    padding: 0.5rem;
    margin-bottom: 10px;
    border-color: Theme.$mainColor;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .imageLink {
    a {
      @include Common.commonLink;
    }
  }
  .scoreTable {
    width: 100%;
    tbody {
      tr {
        :last-child {
          border-right: 1px solid;
        }
      }
      td {
        color: rgb(122, 122, 122);
        text-align: right;
        border-left: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.1rem;
      }
      .subject {
        width: 70%;
        text-align: center;
        background-color: Theme.$subColor;
      }
    }
  }
  .gradeTable {
    width: 100%;
    tbody {
      tr {
        :last-child {
          border-right: 1px solid;
        }
      }
      td {
        color: rgb(122, 122, 122);
        text-align: right;
        border-left: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.1rem;
      }
      .subject {
        width: 70%;
        text-align: center;
        background-color: Theme.$subColor;
      }
    }
  }
  .tabIconHoukoku {
    color: Theme.$mainColor;
    font-size: 1.3rem;
    margin-left: 0.5rem;
    svg {
      transform: rotate(30deg);
    }
  }
  .tabIconTest {
    color: Theme.$commonRightColor;
    font-size: 1.3rem;
  }
  .tabIconSeiseki {
    color: Theme.$commonLeftColor;
    font-size: 1.3rem;
  }
  .tabTitle {
    font-size: 0.5rem;
    color: #495057;
  }
}
.settlementFormWrapper {
  .header {
    margin: 1rem 0.5rem 0 0.5rem;
    background-color: Theme.$subColor;
    border: none;
    .title {
      text-align: center;
      color: #7a7a7a;
      font-size: 0.75rem;
      margin-top: 0.7rem;
    }
  }
  .card {
    margin: 1rem;
    padding: 1rem;
    margin-bottom: 10px;
    border-color: Theme.$mainColor;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    .totalAmount {
      border: none;
      background-color: Theme.$subColor;
      margin: 1rem;
      padding: 0.5rem 0.5rem 0 0.5rem;
    }
  }
  .pagerBtn {
    background-color: Theme.$subColor;
    width: 45px;
    height: 45px;
    color: #7f7f7f;
  }
  .settlementTable {
    width: 100%;
    th {
      width: 6rem;
      text-align: left;
      font-weight: normal;
    }
    td {
      text-align: left;
      padding-left: 1rem;
      border-bottom: 1.5px solid rgb(122, 122, 122);
    }
  }
  .settlementDetail {
    margin-top: 10px;
  }
  .settlementDetailTable {
    width: 100%;
    th {
      font-weight: normal;
    }
    td {
      text-align: center;
    }
  }
}
:global {
  .report-tabs {
    margin-top: 0.5rem;
    border-color: Theme.$mainColor !important;
    .nav-link {
      margin-bottom: -2.3px !important;
      &.active {
        border-color: Theme.$mainColor Theme.$mainColor #fff !important;
      }
    }
  }
  .trash-btn {
    background-color: #fff;
    color: #e7402a;
    border: none;
    top: -0.2rem;
    height: 25px !important;
    box-shadow: none !important;
    &:focus {
      background-color: #fff;
      color: #e7402a;
      border: none;
      height: 25px;
      box-shadow: none !important;
    }
    &:hover {
      background-color: #fff !important;
      color: #c43825;
    }
  }
}
