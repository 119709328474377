@use 'src/Theme';
@use 'src/Variable';

@mixin pageHeader {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: Theme.$subColor;
  border-radius: 3px;
  padding: 1rem;
  border: none;
}
@mixin pageTitle {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  color: Theme.$mainColor;
}
@mixin commonLink {
  color: Variable.$linkColor;
  cursor: pointer;
  text-decoration: none;
}
