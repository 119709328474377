@use 'src/Theme';

.table {
  margin-right: 10px;
}

.kana {
  font-size: 0.8em;
}
.description {
  font-size: 0.7em;
  margin-bottom: 1rem;
}
.memo {
  font-size: 0.7em;
  .link {
    cursor: pointer;
    color: blue;
    border-bottom: #0a58ca 1px solid;
    margin-right: 5px;
  }
}
.priceList {
  border: #d1e7dd 1px solid;
  table-layout: fixed;
  width: 100%;
  th {
    font-weight: normal;
    font-size: 0.7em;
    border: #d1e7dd 1px solid;
    background-color: #d1e7dd;
  }
  td {
    font-size: 0.7em;
    border: #d1e7dd 1px solid;
  }
}

.confirmation {
  font-size: 0.8em;
}
.multiAmountText {
  background-color: #d1e7dd;
  border-radius: 10px;
  padding: 8px;
  ul {
    margin: 0;
    padding-left: 1rem;
  }
  li {
    list-style: none;
    input {
      margin-right: 5px;
    }
  }
}
.textDelivery {
  margin-top: 10px;
  border-radius: 10px;
  padding: 8px;
  background-color: #e5c7ca;
}
.agreement {
  margin-top: 10px;
  background-color: #f0f0f0;
}
.confirmButton {
  margin-top: 0.7rem;
  text-align: center;
}
:global {
  .purchaseConfirm.form-check .form-check-input {
    float: unset;
    margin-right: 0.2rem;
  }
}
.agreement {
  padding: 8px;
  border-radius: 10px;
  .subtitle {
    font-size: 1.4em;
  }
  .information {
    color: red;
  }
  .warning {
    padding: 3px;
    margin-right: 0.5rem;
    background-color: red;
    color: white;
  }
  .warnText {
    color: red;
  }
  .underLine {
    border-bottom: red 1px solid;
  }
  .agreementList {
    list-style-position: outside;
    list-style-type: decimal;
  }
}
.reach {
  margin-bottom: 40px;
}
