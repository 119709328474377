@use 'src/Theme';

.textTable {
  border: 1px solid #ddd;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 1rem;
  tr {
    th {
      padding-left: 8px;
      border: 1px solid #ddd;
      background-color: #e7f1ff;
    }
    td {
      padding-left: 8px;
      border: 1px solid #ddd;
    }
    td.totalTitle {
      background-color: #e7f1ff;
      padding-right: 8px;
      text-align: right;
    }
    td.numCol {
      text-align: right;
      padding-right: 0.25rem;
    }
  }
  tr.normalCol {
    background-color: #fefeee;
  }
}
