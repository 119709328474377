.memo {
  font-size: 0.7em;
}
.button {
  width: 200px;
}
.warningOrientation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  horiz-align: center;
  background-color: white;
  .warningOrientationMessage {
    position: fixed;
    text-align: center;
    width: 100%;
    top: 50%;
    font-size: 0.7em;
  }
}

.signCanvas {
  border: #6c757d 1px solid;
}
.buttonContainer {
  width: 100%;
  text-align: center;
  button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 1004;
  .modalBody {
    background-color: white;
    margin: 0;
    padding: 10px;
  }
  .modalFooter {
    height: auto;
    width: 100%;
    background-color: white;
    min-height: 100%;
    border-radius: 0;
    text-align: right;

    div {
      display: inline-block;
      margin-right: 5px;
    }
    button {
      display: inline-block;
      margin-right: 5px;
    }
  }
  //top: 50%;
  //left: 50%;
  //display: block;
  //min-width: 90em;
  //max-width: 100%;
  //background-color: #fff;
  ////border-radius: 1em;
  //transform: translate(-50%, -50%);
  //outline: transparent;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
