@use 'src/Theme';
@use 'src/Common';

.wrapper {
  .header {
    @include Common.pageHeader;
    .pageTitle {
      @include Common.pageTitle;
    }
  }
}

.menuPanel {
  margin: 5px;
}
.subtitle {
  font-size: 1.3em;
}

.contract {
  margin-right: 10px;
}
.link {
  color: blue;
  border-bottom: #0a53be 1px solid;
  cursor: pointer;
}
