@use 'src/Theme';
@use 'src/Variable';
@use 'src/Common';

.loginWrapper {
  text-align: center;
  .mainTitle {
    font-size: 1.6em;
  }
  .form {
    padding: 24px 16px;
    margin-top: 0.5rem;
    display: inline-block;
    border-radius: 10px;
    background-color: Theme.$subColor;
    width: 100%;
    text-align: left;
  }
  .footer {
    margin-top: 2rem;
  }
  .passwordInformation {
    margin-top: 1rem;
  }
  .link {
    @include Common.commonLink;
  }
}

.passwordWrapper {
  .contents {
    padding: 4px;
  }
  .form {
    padding: 16px;
    margin-top: 1vh;
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    background-color: Theme.$subColor;
    .description {
      margin-bottom: 1rem;
    }
    .footer {
      margin-top: 2rem;
    }
  }
  .toLogin {
    margin-top: 20px;
    text-align: center;
    a {
      @include Common.commonLink;
    }
  }
}
