@use 'src/Variable';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: Variable.$baseFontColor;
  font-size: Variable.$baseFontSize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
textarea {
  resize: none;
}

/* React-Bootstrap override */
/* InputGroup Feedback */
:global {
  .invalid-feedback {
    word-wrap: break-word;
  }
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    opacity: 1;
  }
}
